import React from "react"
import Layout from "../components/layout"
import {
  Title,
  Overview,
  Toolset,
  Involvement,
  Visit,
  WorkedWith,
  Project,
  Car,
} from "../components/parts"

const KatiKati = () => {
  return (
    <Layout title="KatiKati">
      <Title heading="KatiKati" sub="Large scale 1:1 conversations" />
      <Overview
        project="katikati"
        text={[
          "Katikati enables organizations to configure and comprehend personal free-text SMS-based conversations with their counterparts at scale. As a result, the product stands apart from other offerings that focus on data extraction, standardized surveys, impersonal chatbots, or spamming bulk messages out.",
          "I co-built parts of the user interface for conversations, configuring messages and tags while continuously fixing bugs and usability issues.",
        ]}
      />

      <Project
        heading="KatiKati Conversations"
        sub="1:1 conversations at scale"
        style={{ marginBottom: 0 }}
      />
      <div className="section-bottom">
        <Car
          images={[1, 2, 3, 4].map(i => `/images/katikati/converse-${i}.png`)}
          bordered
        />
      </div>

      <Project
        heading="UI Component Library"
        sub="Reusable elements with design constraints"
        style={{ marginBottom: 0 }}
      />
      <div className="section-bottom">
        <Car
          images={[1, 2].map(i => `/images/katikati/component-lib-${i}.png`)}
          bordered
        />
      </div>

      <Toolset project="katikati" />
      <Involvement project="katikati" />
      <Visit project="katikati" />
      <WorkedWith project="katikati" />
    </Layout>
  )
}

export default KatiKati
